import React, {useEffect} from 'react';
import {Button} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import deltaLogo from '../../../images/DeltaLogo.svg';
import {useContext} from 'react';
import {userContext} from '../../../contexts/UserContext';
import styles from './LandingPage.module.css';
import './effects.css';

//@ts-ignore
import {TimelineMax, Quint} from 'gsap';

const effectfn = () => {
	class TextFX {
		DOM: {
			texts?: any[];
			textsTotal?: any;
			el: any;
		};
		middleIdx: number;
		loopInterval: {show: number; hide: number};
		loopEndIddleTime: any;
		constructor(el: any) {
			this.DOM = {el: el};
			// The texts (repeated)
			this.DOM.texts = [...this.DOM.el.querySelectorAll('.content__text')];
			this.DOM.textsTotal = this.DOM.texts.length;
			// The index of the main text element
			this.middleIdx = Math.floor(this.DOM.textsTotal / 2);
			// Time between each showing/hiding of each text instance
			this.loopInterval = {show: 80, hide: 80};
			// optional: Extra time to the whole show/hide animation.
			this.loopEndIddleTime = this.loopInterval.show;
		}
		show({dir = 'both', halfwayCallback = null} = {}) {
			return new Promise((resolve, reject) => {
				const loopHide = (pos: any) => {
					if (this.middleIdx - pos === this.middleIdx) {
						setTimeout(resolve, this.loopEndIddleTime);
						return;
					}
					this.hideText(pos, dir);
					setTimeout(() => loopHide(pos - 1), this.loopInterval.hide);
				};
				const loopShow = (pos: any) => {
					if (this.middleIdx - pos > this.middleIdx) {
						if (halfwayCallback && typeof halfwayCallback === 'function') {
							//@ts-ignore
							halfwayCallback();
						}
						loopHide(this.middleIdx);
						return;
					}
					this.showText(pos, dir);
					setTimeout(() => loopShow(pos - 1), this.loopInterval.show);
				};
				loopShow(this.middleIdx);
			});
		}
		hide({dir = 'both', halfwayCallback = null} = {}) {
			return new Promise((resolve, reject) => {
				const loopHide = (pos: any) => {
					if (this.middleIdx - pos < 0) {
						setTimeout(resolve, this.loopEndIddleTime);
						return;
					}
					this.hideText(pos, dir);
					setTimeout(() => loopHide(pos + 1), this.loopInterval.hide);
				};
				const loopShow = (pos: any) => {
					if (this.middleIdx - pos < 0) {
						if (halfwayCallback && typeof halfwayCallback === 'function') {
							//@ts-ignore
							halfwayCallback();
						}
						loopHide(0);
						return;
					}
					this.showText(pos, dir);
					setTimeout(() => loopShow(pos + 1), this.loopInterval.show);
				};
				loopShow(1);
			});
		}
		// Hides one (dir = 'up' or dir = 'down') or more texts, specifically the equally distant texts from main text (dir = 'both')
		hideText(pos: any, dir: any) {
			this.toggleText('hide', pos, dir);
		}
		showText(pos: any, dir: any) {
			this.toggleText('show', pos, dir);
		}
		toggleText(action: any, pos: any, dir: any) {
			const changeStyle = {
				up: () => {
					//@ts-ignore
					this.DOM.texts[this.middleIdx - pos].style.opacity = action === 'show' ? 1 : 0;
				},
				down: () => {
					//@ts-ignore
					this.DOM.texts[this.middleIdx + pos].style.opacity = action === 'show' ? 1 : 0;
				},
			};
			if (dir === 'both') {
				changeStyle['up']();
				changeStyle['down']();
			} else {
				//@ts-ignore
				changeStyle[dir]();
			}
		}
	}

	class Slide {
		DOM: {
			img?: {wrap: any; inner: any};
			el: any;
		};
		textFX: TextFX;
		constructor(el: any) {
			this.DOM = {el: el};
			// The text effect class.
			this.textFX = new TextFX(this.DOM.el.querySelector('.content__text-wrap'));
		}
		// Hide the Slide's image
		hideImage(dir: any) {
			this.toggleImage('hide', dir);
		}
		// Show the Slide's image
		showImage(dir: any) {
			this.toggleImage('show', dir);
		}
		toggleImage(action: string, dir: string) {
			new TimelineMax()
				.add('begin')
				//@ts-ignore
				.to(
					this.DOM.img?.wrap,
					action === 'hide' ? 0.3 : 0.7,
					{
						ease: action === 'hide' ? Quint.easeOut : Quint.easeOut,
						startAt: action === 'hide' ? {} : {x: dir === 'next' ? '110%' : '-110%', opacity: 1},
						x: action === 'hide' ? (dir === 'next' ? '-110%' : '110%') : '0%',
					},
					'begin'
				)
				//@ts-ignore
				.to(
					this.DOM.img?.inner,
					action === 'hide' ? 0.3 : 0.7,
					{
						ease: action === 'hide' ? Quint.easeOut : Quint.easeOut,
						startAt: action === 'hide' ? {} : {x: dir === 'next' ? '-100%' : '100%'},
						x: action === 'hide' ? (dir === 'next' ? '100%' : '-100%') : '0%',
					},
					'begin'
				);
		}
	}

	class Slideshow {
		DOM: {
			nav?: {prev: any; next: any};
			el: any;
		};
		slides: any[];
		slidesTotal: any;
		current: number;
		isAnimating: any;
		constructor(el: any) {
			this.DOM = {el: el};
			// Navigation controls
			// All slides
			this.slides = [];
			[...this.DOM.el.querySelectorAll('.content__slide')].forEach((slide) => this.slides.push(new Slide(slide)));
			// Total number of slides
			this.slidesTotal = this.slides.length;
			// Current slide position
			this.current = 0;
			// Show the first one
			this.slides[this.current].DOM.el.classList.add('content__slide--current');
			// Initialize some events
			this.initEvents();
		}
		initEvents() {
			this.navigate('prev');
		}
		navigate(dir: string) {
			if (this.isAnimating) {
				return false;
			}
			this.isAnimating = true;
			const currentSlide = this.slides[this.current];

			// Upcoming slide
			const upcomingSlide = this.slides[this.current];

			const onCurrentHalfwayCallback = () => {
				// Hide the current slide's image
				// currentSlide.hideImage(dir);
				// Set the upcoming slide's main text opacity to 1.
				upcomingSlide.textFX.DOM.texts[upcomingSlide.textFX.middleIdx].style.opacity = 0;
				// Add current class to the upcoming slide (opacity = 1)
				// upcomingSlide.DOM.el.classList.add('content__slide--current');
				// Show the upcoming slide's image
				// upcomingSlide.showImage(dir);
			};
			const onCurrentEndCallback = () => {
				// Remove the current class from the current slide.
				// currentSlide.DOM.el.classList.remove('content__slide--current');
				upcomingSlide.textFX.show().then(() => (this.isAnimating = false));
			};
			// Hide the current slide's text,
			// and call onCurrentHalfwayCallback at half of the animation
			// In the end call onCurrentEndCallback
			currentSlide.textFX.hide({halfwayCallback: onCurrentHalfwayCallback}).then(onCurrentEndCallback);
		}
	}

	// Initialize the slideshow
	new Slideshow(document.querySelector('.content'));
};

const LandingPage = () => {
	useEffect(() => {
		effectfn();
		const effectInterval = setInterval(effectfn, 5000);
		return () => {
			clearInterval(effectInterval);
		};
	}, []);

	const {loading, isLoggedIn, isNewUser} = useContext(userContext);
	return (
		<div className={styles.landingpage}>
			<div className={styles.buttonNav}>
				{loading ? (
					true
				) : isLoggedIn ? (
					isNewUser ? (
						<Button className={styles.navButton} inverted as={Link} to="/auth/info" color="green">
							Register
						</Button>
					) : (
						<Button className={styles.navButton} inverted as={Link} to="/dashboard" color="green">
							Dashboard
						</Button>
					)
				) : (
					<Button className={styles.navButton} inverted as={Link} to="/auth/login" color="green">
						Login
					</Button>
				)}
			</div>
			<div className={styles.page1}>
				<div className={styles.landinglogoAndText}>
					<div className={styles.logo}>
						<img src={deltaLogo} alt="" />
					</div>
					<div className={styles.landingtext + ' content'}>
						<div className="content__slide">
							<div className="content__text-wrap">
								{[1, 2, 3, 4, 5, 6, 7].map((ele) => (
									<span className={'content__text' + (ele === 4 ? ' imp_text' : '')}>
										<span
											className={
												'content__text-inner' +
												(ele === 4 ? '' : ' content__text-inner--stroke')
											}>
											INDUCTIONS '25
										</span>
									</span>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LandingPage;
