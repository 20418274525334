import React from 'react';
import styles from './Register.module.css';

const style = {
	fixedSize: {
		minWidth: '150px',
		marginTop: '1rem',
	},
};

const InductionInfo = () => {
	return (
		<div className={styles.informationBox} style={{width: '110%!important'}}>
			{/* <h1>Inductions</h1> */}
			<p>
				Greetings first years! Please fill out the form below to apply for Delta! Once you submit the form, we
				will be in touch shortly regarding mentorship details and future steps. We look forward to all the cool
				things you'll be building throughout the summer! Good luck!
			</p>
			<p>About each profile :-</p>
			<ul>
				<li>
					<strong>Web Development - </strong>If conceptualizing a project from start to finish is your thing
					and you want to build the backbone of a project, then this profile is for you! Our Web Developers
					create the smooth frontend that the users interact with as well as the robust backend that all of
					our applications communicate with.
				</li>
				<br />
				<li>
					<strong>App Development - </strong>There's no denying that mobile applications are the future. Our
					App Developers are the minds behind the Android apps for Festember, Pragyan and much more! If you're
					looking forward to building an application that lives in the pockets of everyone on campus, sign up
					for this profile!
				</li>
				<br />
				<li>
					<strong>System Administrators - </strong>Once a project has been developed, we look to our SysAds to
					help us push it into production. They manage all of our servers and are the first ones on the scene
					if an error occurs. If you live within the Terminal, and prefer a CLI over those pesky GUIs, then
					this profile is perfect for you!
				</li>
			</ul>
			<br />
			<p>For any queries: </p>
			{/* <a
        className="btn btn-outline-info"
        href="https://inductions.delta.nitt.edu/faq"
      >
        FAQ
      </a> */}
			<p>Contacts:</p>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-around',
					alignItems: 'center',
					flexWrap: 'wrap',
				}}
				className="hot-singles-in-your-area">
				<div style={style.fixedSize}>
					<strong>Miruthul (Web)</strong>
					<p>+91 91597 15289</p>
				</div>
				<div style={style.fixedSize}>
					<strong>Sai (SysAd)</strong>
					<p>+91 94475 59989</p>
				</div>
				<div style={style.fixedSize}>
					<strong>Shriram (App)</strong>
					<p>+91 93456 41731</p>
				</div>
			</div>
			<br />
			<br />
		</div>
	);
};

export {InductionInfo};
